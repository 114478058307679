import {
  reactive, ref, computed, watch,
} from '@vue/composition-api'
import http from '@/global/http'
import store from '@/store'
import { getBeforeMonth } from '@/utils/helpers'

export default function useOverview({ departmentId }) {
//   const toast = useToast()
  const fromMeta = ref(null)
  const toMeta = ref(null)

  const m = new Date().getMonth() + 1
  const y = new Date().getFullYear()

  const timeFilter = reactive({
    monthStart: ref(m),
    yearStart: ref(y),
    monthEnd: ref(m),
    yearEnd: ref(y),
  })

  const timeFilterChart = reactive({
    monthStart: ref(m),
    yearStart: ref(y),
    monthEnd: ref(m),
    yearEnd: ref(y),
  })

  const chartVerticalFilter = reactive({
    month: ref(m > 9 ? m : `0${m}`),
    year: ref(y),
  })

  const timeReportDepartment = computed(() => {
    const monthToReal = new Date(toMeta.value.data?.meta_value).setDate(0)
    const monthFrom = new Date(fromMeta.value.data?.meta_value).getMonth() + 1
    const yearFrom = new Date(fromMeta.value.data?.meta_value).getFullYear()
    const monthTo = new Date(monthToReal).getMonth() + 1
    const yearTo = new Date(monthToReal).getFullYear()
    return {
      monthFrom: String(monthFrom).padStart(2, '0'),
      yearFrom,
      monthTo: String(monthTo).padStart(2, '0'),
      yearTo,
    }
  })
  const sumData = ref(null)
  const hashMemberNameById = computed(() => {
    const members = sumData.value?.sum_transactions || []
    return members.reduce((acc, item) => {
      acc[item.user_id] = item.user_name
      return acc
    }, {})
  })
  const sumDataChart = ref(null)
  const sumDepartment = ref([])
  const sumDepartmentCurrentMonth = ref([])
  const reportDepartmentHS = computed(() => {
    const data = sumData.value?.report_department_HS || {}
    return {
      ...data,
      offer_to_sign: data.total_offer_to_sign || 0,
    }
  })
  const reportDepartmentHD = computed(() => sumData.value?.report_department_HD)
  const departmentVote = ref([])
  const namesWithMosteVote = computed(() => {
    const mostVote = Math.max(...departmentVote.value.map(el => Number(el.vote_count)))
    return departmentVote.value.filter(el => Number(el.vote_count) === mostVote).map(el => hashMemberNameById.value[el.user_id])
  })
  const getSalaryTeam = (transactions = []) => {
    let amountTeamLeader = 0
    let amountTeamMember = 0

    transactions.forEach(item => {
      if (item.job_title_code === 'TDPC') {
        amountTeamLeader = item.total || 0
      }
      if (item.job_title_code === 'DPC') {
        amountTeamMember = amountTeamMember || item.total
      }
    })

    return {
      amountTeamLeader,
      amountTeamMember,
    }
  }

  const dataDoanhThu = computed(() => {
    const data = (sumData.value || {}).debt_detail || {}

    const transactions = data.sum_transactions || []
    const {
      amountTeamLeader,
      amountTeamMember,
    } = getSalaryTeam(transactions)
    return [{
      ...data,
      amountTeamLeader,
      amountTeamMember,
    }]
  })

  const customFieldReport = computed(() => [
    {
      label: 'Số HĐ đã thu nợ',
      value: dataDoanhThu.value[0].debt_count,
    },
    {
      label: 'Tổng số tiền đã thu',
      value: `${http.formatNumber(dataDoanhThu.value[0].total)} đ`,
    },
    {
      label: 'Doanh thu đội',
      value: `${http.formatNumber(dataDoanhThu.value[0].total_amount_for_team)} đ`,
    },
    {
      label: 'Lương nhân viên',
      value: `${http.formatNumber(dataDoanhThu.value[0].amountTeamMember)} đ`,
    },
    {
      label: 'Tổng tiền giữ lại',
      value: `${http.formatNumber(dataDoanhThu.value[0].total_keep_amount)} đ`,
    },
  ])

  const debtCollectionReportList = ref([])

  const getQuery = () => http.buildQuery({
    from: fromMeta.value.data?.meta_value,
    to: toMeta.value.data?.meta_value,
  }) || ''

  const fetchSumMe = async (filter = {}) => {
    const from = new Date(timeFilter.yearStart, timeFilter.monthStart ? timeFilter.monthStart - 1 : 0, 2)
    const to = new Date(timeFilter.yearEnd, timeFilter.monthEnd ? timeFilter.monthEnd : 12, 1)
    const queryString = http.buildQuery({
      from: http.getISODate(from),
      to: http.getISODate(to),
    }) || ''
    http.api(store.dispatch('report/sumMe', queryString), res => {
      sumData.value = res.data || {}
    })
  }

  const fetchDebtCollectionDetailReport = () => {
    const from = new Date(timeFilter.yearStart, timeFilter.monthStart ? timeFilter.monthStart - 1 : 0, 2)
    const to = new Date(timeFilter.yearEnd, timeFilter.monthEnd ? timeFilter.monthEnd : 12, 1)
    const queryString = http.buildQuery({
      from: http.getISODate(from),
      to: http.getISODate(to),
      department_id: departmentId,
    })

    http.api(store.dispatch('report/fetchDebtCollectionReport', queryString), res => {
      debtCollectionReportList.value = (res.data || []).reverse()
      return debtCollectionReportList.value
    })
  }

  const fetchSumMeChart = async (filter = {}) => {
    fromMeta.value = await http.api(store.dispatch('meta/fetchMeta', { name: 'from_report_department' }))
    toMeta.value = await http.api(store.dispatch('meta/fetchMeta', { name: 'to_report_department' }))
    const queryString = getQuery(filter)
    http.api(store.dispatch('report/sumMe', queryString), res => {
      sumDataChart.value = res.data || {}
    })
    http.api(store.dispatch('report/sumDepartment', queryString), res => {
      sumDepartment.value = ((res.data || {}).report_deparment || []).filter(el => el.department_name !== 'Tổng')
    })
  }

  // const fetchSumDepartment = async (filter = {}) => {
  //   const from = new Date(timeFilterChart.yearStart, timeFilterChart.monthStart ? timeFilterChart.monthStart - 1 : 0, 2)
  //   const to = new Date(timeFilterChart.yearEnd, timeFilterChart.monthEnd ? timeFilterChart.monthEnd : 12, 1)
  //   const queryString = http.buildQuery({
  //     from: http.getISODate(from),
  //     to: http.getISODate(to),
  //   }) || ''

    
  // }

  const fetchSumDepartmentCurrentMonth = async () => {
    const {
      year,
      month,
    } = chartVerticalFilter

    if (!year && !month) {
      http.api(store.dispatch('report/sumDepartment'), res => {
        sumDepartmentCurrentMonth.value = ((res.data || {}).report_deparment || []).filter(el => el.department_name !== 'Tổng')
      })
    }
    if (!year) {
      return null
    }

    const from = !month ? new Date(year, 0, 1)
      : new Date(year, parseFloat(month - 1), 1)
    const to = !month ? new Date(year, 12, 0)
      : new Date(year, month, 0, 23, 59, 59)

    const queryString = http.buildQuery({
      from: http.getISODate(from),
      to: http.getISODate(to),
    }) || ''
    http.api(store.dispatch('report/sumDepartment', queryString), res => {
      sumDepartmentCurrentMonth.value = ((res.data || {}).report_deparment || []).filter(el => el.department_name !== 'Tổng')
    })
  }

  const fetchDepartmentVote = async (filter = {}) => {
    const { month, year } = getBeforeMonth()
    console.log({
      month,
      year,
    })
    const query = http.buildQuery({
      month,
      year,
    })
    if (!departmentId) return
    http.api(store.dispatch('report/fetchDepartmentVote', { id: departmentId, query }), res => {
      departmentVote.value = res.data
    })
  }

  watch([
    computed(() => timeFilter.yearStart),
    computed(() => timeFilter.yearEnd),
    computed(() => timeFilter.monthStart),
    computed(() => timeFilter.monthEnd),
  ], () => {
    fetchSumMe()
  })

  watch([
    computed(() => chartVerticalFilter.month),
    computed(() => chartVerticalFilter.year),
  ], () => {
    fetchSumDepartmentCurrentMonth()
  })
  // watch([
  //   computed(() => timeFilterChart.yearStart),
  //   computed(() => timeFilterChart.yearEnd),
  //   computed(() => timeFilterChart.monthStart),
  //   computed(() => timeFilterChart.monthEnd),
  // ], () => {
  //   fetchSumDepartment()
  // })

  return {
    fetchSumMe,
    fetchSumMeChart,
    // fetchSumDepartment,
    fetchSumDepartmentCurrentMonth,
    fetchDepartmentVote,
    fetchDebtCollectionDetailReport,
    // fetchSumDepartment,
    month: m,
    timeFilter,
    timeFilterChart,
    timeReportDepartment,
    chartVerticalFilter,
    sumData,
    sumDataChart,
    sumDepartment,
    sumDepartmentCurrentMonth,
    dataDoanhThu,
    customFieldReport,
    reportDepartmentHS,
    reportDepartmentHD,
    namesWithMosteVote,
    debtCollectionReportList,
  }
}
